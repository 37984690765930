import ERC20 from "@sushiswap/core/build/contracts/ERC20.json";
import IUniswapV2Factory from "@sushiswap/core/build/contracts/IUniswapV2Factory.json";
import IUniswapV2Pair from "@sushiswap/core/build/contracts/IUniswapV2Pair.json";
import IUniswapV2Router02 from "@sushiswap/core/build/contracts/IUniswapV2Router02.json";
import IWETH from "@sushiswap/core/build/contracts/IWETH.json";
import MasterChef from "@sushiswap/core/build/contracts/MasterChef.json";
import SushiBar from "@sushiswap/core/build/contracts/SushiBar.json";
import SushiRoll from "@sushiswap/core/build/contracts/SushiRoll.json";
import OrderBook from "@sushiswap/settlement/deployments/kovan/OrderBook.json";
import Settlement from "@sushiswap/settlement/deployments/mainnet/Settlement.json";
import ZapIn from "@sushiswap/zapper/artifacts/Sushiswap_ZapIn_General_V2.json";
import ZapOut from "@sushiswap/zapper/artifacts/Sushiswap_ZapOut_General_V1.json";
import { ethers } from "ethers";
import LPTokenScanner from "../constants/abi/LPTokenScanner.json";

import IBEP20 from "../core/build/contracts/IBEP20.json";
import IMiningFarm from "../core/build/contracts/IMiningFarm.json";
import ISTokenERC20 from "../core/build/contracts/ISTokenERC20.json";
import MayaBridge from "../core/build/contracts/MayaBridge.json";
import V2FarmWithApiWithUpgrade from "../core/build/contracts/V2FarmWithApiWithUpgrade.json";
import {TronWebProvider} from "../connectors/tron/tronWebProvider"
import {TRON_ADDRESS_MAPPING} from "../constants/contracts"

const CONTRACTS = {
    IBEP20,
    MayaBridge,
    IMiningFarm,
    ISTokenERC20,
    V2FarmWithApiWithUpgrade,
    ERC20,
    IUniswapV2Factory,
    IUniswapV2Pair,
    IUniswapV2Router02,
    IWETH,
    MasterChef,
    SushiBar,
    SushiRoll,
    OrderBook,
    Settlement,
    LPTokenScanner: { abi: LPTokenScanner },
    ZapIn,
    ZapOut
};

const getContract = (name: string, address: string, signerOrProvider: ethers.Signer | ethers.providers.Provider) => {
    const contract = CONTRACTS[name];
    return new ethers.Contract(address, contract.abi, signerOrProvider);
};

const getAsyncContract = async (name: string, address: string, signerOrProvider: ethers.Signer | ethers.providers.Provider) => {
    const contract = CONTRACTS[name];
    console.log("signerOrProvider",signerOrProvider);
    if (isProviderOnTron(signerOrProvider)){
        const provider = signerOrProvider.provider as TronWebProvider;
        let tronAddr = address;
        if (!address.startsWith("t") && !address.startsWith("T")){
            tronAddr = TRON_ADDRESS_MAPPING[address];
        }
        
        console.log("tronAddr",tronAddr);
        const contractImpl = await provider.getTron().contract(contract.abi).at(tronAddr);
        return contractImpl;
    }
    return new ethers.Contract(address, contract.abi, signerOrProvider);
};

export async function tronWrapRspToEth(tx:any){
    let result = {wait:async()=>{return true},hash:tx+"",value:ethers.BigNumber.from(0),
                            gasLimit:ethers.BigNumber.from(0),gasPrice:ethers.BigNumber.from(0),chainId:111};
    return result;
}

export async function transactionResultManager(transaction, tronWeb) {
  
    const signedTransaction = await tronWeb.trx.sign(transaction, tronWeb.defaultPrivateKey).catch(e => {
      console.log(e.toString());
      return false;
    });
    
    if (signedTransaction) {
      const broadcast = await tronWeb.trx.sendRawTransaction(signedTransaction);
      if (!broadcast.result) {
        broadcast.result = false;
      }
      return broadcast;
    } else {
      return false;
    }
}

export async function tronAsyncCall(tronWeb,funcName:string,contractAddress:string,param:any,from:string){
    let unSignTransaction = await tronWeb.transactionBuilder.triggerSmartContract(
        tronWeb.address.toHex(contractAddress),
        funcName,// 'transfer(address,uint256)',
        {},// {'permissionId':permissionId},
        param,
        // [
        //     {type: 'address', value: tronWeb.address.toHex(to)},
        //     {type: 'uint256', value: new BigNumber(amount).shiftedBy(decimals).toString()}
        // ],
        tronWeb.address.toHex(from),
    );
    if (unSignTransaction.transaction !== undefined)
        unSignTransaction = unSignTransaction.transaction;
    
    
    // Signing the transaction
    const signedTransaction = await tronWeb.trx.sign(unSignTransaction);
    if (!signedTransaction.signature) {
        console.error('Transaction was not signed properly');
        throw new Error("Transaction was not signed properly")
    }
    // Broadcasting the transaction
    const broadcast = await tronWeb.trx.sendRawTransaction(signedTransaction);
    console.log(`broadcast: ${broadcast}`);

}


export async function transactionMultiResultManager(unSignTransaction, tronWeb, permissionId, permissionTime, HexStr) {
    //set transaction expiration time (1H-24H)
    const newTransaction = await tronWeb.transactionBuilder.extendExpiration(unSignTransaction, (3600*permissionTime-60));
    if(unSignTransaction.extra){
        newTransaction.extra = unSignTransaction.extra;
    }

    //sign transaction
    const signedTransaction = await tronWeb.trx.multiSign(newTransaction, tronWeb.defaultPrivateKey , permissionId).catch(e => {
        console.log('e',e)
        return false;
    });
    //set transaction hex parameter value
    if(HexStr && signedTransaction){
        signedTransaction.raw_data.contract[0].parameter.value = HexStr;
    }
    // return transaction
    return signedTransaction;
}

export function isProviderOnTron(signerOrProvider: ethers.Signer | ethers.providers.Provider){
    if (signerOrProvider.provider && signerOrProvider.provider.isTron){
        return true;
    }
    return false;
}
export {
    getAsyncContract
}
export default getContract;
