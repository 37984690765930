import { AbstractConnectorArguments, ConnectorUpdate } from '@web3-react/types'
import { AbstractConnector } from '@web3-react/abstract-connector'
import warning from 'tiny-warning'
import {TronWebProvider} from './tronWebProvider'

import { SendReturnResult, SendReturn, Send, SendOld } from './types'

export class NoTronWebProviderError extends Error {
    public constructor() {
        super()
        this.name = this.constructor.name
        this.message = 'No TronWeb provider was found on window.'
    }
}
export class UserRejectedRequestError extends Error {
    public constructor() {
        super()
        this.name = this.constructor.name
        this.message = 'The user rejected the request.'
    }
}
export class TronWebNotLoginError extends Error{
    public constructor() {
        super()
        this.name = this.constructor.name
        this.message = 'The tron user not loggedin'
    }
}
export class TronWebConnector extends AbstractConnector{
    public tronAccount;
    public hexAccount;
    public conformToEthAccount;
    public provider;
    constructor(kwargs: AbstractConnectorArguments) {
        super(kwargs);
        if (!window.tronWebProvider){
            window.tronWebProvider = new TronWebProvider();
        }
        this.provider = window.tronWebProvider;
    }
    private setHexAccount(hex_:string){
        this.hexAccount = hex_;
        this.conformToEthAccount = "0x"+hex_.substr(0,40)
    }
    private handleChainChanged(chainId: string | number): void {
        this.emitUpdate({ chainId, provider: this.provider })
    }

    private handleAccountsChanged(accounts: string[]): void {
    if (accounts.length === 0) {
        this.emitDeactivate()
    } else {
        this.emitUpdate({ account: accounts[0] })
    }
    }

    private handleClose(): void {
        this.emitDeactivate()
    }

    private handleNetworkChanged(networkId: string | number): void {
    this.emitUpdate({ chainId: networkId, provider: this.provider })
    }
    public async activate(): Promise<ConnectorUpdate> {
        if (!window.tronWeb) {
            throw new NoTronWebProviderError()
        }
        if (window.tronWeb.on){
            window.tronWeb.on('chainChanged', this.handleChainChanged)
            window.tronWeb.on('accountsChanged', this.handleAccountsChanged)
            window.tronWeb.on('close', this.handleClose)
            window.tronWeb.on('networkChanged', this.handleNetworkChanged)
        }
        
        try{
            if (!window.tronWeb.defaultAddress.base58 || !window.tronWeb.ready){
                throw new TronWebNotLoginError()
            }
            this.tronAccount = window.tronWeb.defaultAddress.base58;
            this.setHexAccount(window.tronWeb.defaultAddress.hex);
        }catch(error){
            if ((error as any).code === 4001) {
                throw new UserRejectedRequestError()
            }
            warning(false, 'eth_requestAccounts was unsuccessful, falling back to enable')
        }
        if (!this.tronAccount){
            throw new TronWebNotLoginError()
        }
        let account = this.conformToEthAccount;
        return {provider:this.provider,...(account ? { account } : {})}
    }
    public deactivate() {
        if (window.tronWeb && window.tronWeb.removeListener){
            window.tronWeb.removeListener('chainChanged', this.handleChainChanged)
            window.tronWeb.removeListener('accountsChanged', this.handleAccountsChanged)
            window.tronWeb.removeListener('close', this.handleClose)
            window.tronWeb.removeListener('networkChanged', this.handleNetworkChanged)    
        }
    }
    public async getChainId(): Promise<number | string> {
        if (!window.tronWeb) {
            throw new NoTronWebProviderError()
        }
        return 1;
    }
    public async getAccount(): Promise<null | string> {
        if (!window.tronWeb) {
            throw new NoTronWebProviderError()
        }
        try{
            if (!window.tronWeb.defaultAddress.base58 || !window.tronWeb.ready){
                throw new TronWebNotLoginError()
            }
            this.tronAccount = window.tronWeb.defaultAddress.base58;
            this.setHexAccount(window.tronWeb.defaultAddress.hex);
        }catch(error){
            if ((error as any).code === 4001) {
                throw new UserRejectedRequestError()
            }
            warning(false, 'eth_requestAccounts was unsuccessful, falling back to enable')
        }
        if (!this.tronAccount){
            throw new TronWebNotLoginError()
        }
        let account = this.conformToEthAccount;
        return account;
    }
    public async getProvider(): Promise<any> {
        return this.provider;
    }
}