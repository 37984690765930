import React, { useCallback, useEffect, useState } from "react";

import * as Analytics from "expo-firebase-analytics";

import AsyncStorage from "@react-native-community/async-storage";
import sushiData from "@sushiswap/sushi-data";
import { ethers } from "ethers";
import useAsyncEffect from "use-async-effect";
import Fraction from "../constants/Fraction";
import { ETH } from "../constants/tokens";
import useSDK from "../hooks/useSDK";
import Ethereum from "../types/Ethereum";
import Token from "../types/Token";
import TokenWithValue from "../types/TokenWithValue";
import { getContract, getSigner, isWETH } from "../utils";
import { logTransaction } from "../utils/analytics-utils";
import { useActiveWeb3React } from '../hooks'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import {ChainId} from "../constants"
import {isProviderOnTron,getAsyncContract,tronWrapRspToEth} from "../utils/getContract"
import {TronWebProvider} from "../connectors/tron/tronWebProvider"
import {tronAddressToHex} from "../utils/api-utils"
import {WalletType} from '../types/Ethereum'
import { isChainIdSupport } from "../constants/contracts";
import { TronWebConnector } from "../connectors/tron/tronwebConnector";
import {TRON_ADDRESS_MAPPING} from "../constants/contracts"
export type OnBlockListener = (block?: number) => void | Promise<void>;

// const PRIVATE_KEY = "0xca417c154948d370f011c5d9ac3fba516d7b15671a069e7d5d48f56b723c9cc1";
// export const ALCHEMY_PROVIDER = new ethers.providers.AlchemyProvider(
//     1,
//     __DEV__ ? process.env.MAINNET_API_KEY : "DgnfFsj5PXR37FkOmUVJ9GtfDsKws446"
// );
// const KOVAN_PROVIDER = new ethers.providers.AlchemyProvider(
//     42,
//     __DEV__ ? process.env.KOVAN_API_KEY : "8a03ORJJcIv8YA49M-cIxg-mBEMJYe0J"
// );

export const EthersContext = React.createContext({
    ethereum: undefined as Ethereum | undefined,
    setEthereum: (_ethereum: Ethereum | undefined) => {},
    provider: undefined as ethers.providers.JsonRpcProvider | undefined,
    signer: undefined as ethers.providers.JsonRpcSigner | undefined,
    kovanSigner: undefined as ethers.Signer | undefined,
    chainId: 0,
    walletType:undefined as WalletType | undefined,
    address: null as string | null,
    ensName: null as string | null,
    addOnBlockListener: (_name: string, _listener: OnBlockListener) => {},
    removeOnBlockListener: (_name: string) => {},
    // tokens: [ETH] as TokenWithValue[],
    // updateTokens: async () => {},
    // loadingTokens: false,
    // customTokens: [ETH] as Token[],
    // addCustomToken: (_token: Token) => {},
    // removeCustomToken: (_token: Token) => {},
    approveToken: async (_token: string, _spender: string, _amount?: ethers.BigNumber) => {
        return {} as ethers.providers.TransactionResponse | undefined;
    },
    getTokenAllowance: async (_token: string, _spender: string) => {
        return ethers.constants.Zero as ethers.BigNumber | undefined;
    },
    getTokenBalance: async (_token: string, _who: string) => {
        return ethers.constants.Zero as ethers.BigNumber | undefined;
    },
    getTotalSupply: async (_token: string) => {
        return ethers.constants.Zero as ethers.BigNumber | undefined;
    }
});

// tslint:disable-next-line:max-func-body-length
export const EthersContextProvider = ({ children }) => {
    // const { library, account, connector,chainId } = useActiveWeb3React();
    const { library, account, connector,chainId } = useActiveWeb3React();
    // const { getPair } = useSDK();
    const [ethereum, setEthereum] = useState<Ethereum | undefined>(window.ethereum);
    const [provider, setProvider] = useState<ethers.providers.JsonRpcProvider>();
    const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner>();
    const [kovanSigner, setKovanSigner] = useState<ethers.Signer>();
    const [walletType,setWalletType] = useState<WalletType>();
    const [chainIdProp, setChainIdProp] = useState<number>(1);
    // const [chainId, setChainId] = useState<number>(1);
    const [address, setAddress] = useState<string | null>(null);
    const [ensName, setENSName] = useState<string | null>(null);
    const [onBlockListeners, setOnBlockListeners] = useState<{ [name: string]: OnBlockListener }>({});
    // const [tokens, setTokens] = useState<TokenWithValue[]>([]);
    // const [customTokens, setCustomTokens] = useState<Token[]>([]);
    // const [loadingTokens, setLoadingTokens] = useState(true);

    // useEffect(() => {
    //     // Kovan
    //     setKovanSigner(new ethers.Wallet(PRIVATE_KEY, KOVAN_PROVIDER));
    // }, [KOVAN_PROVIDER]);
    useEffect(()=>{
        setWalletType(undefined);
    });
    useAsyncEffect(async ()=>{
        if (library && account && chainId){
            console.log("chainID:"+chainId);
            setProvider(library);
            const web3Signer = await library.getSigner();
            setSigner(web3Signer);
            // setSigner(getSigner(library,account));
            let apearAccount = account;
            if (chainId==1 && window.tronWeb){
                console.log("connector account",connector,account);
                if (connector && connector.hexAccount &&
                    ("0x"+connector.hexAccount).toLowerCase().startsWith(account.toLowerCase()) ){
                        apearAccount = connector.tronAccount;
                }
            }

            setAddress(apearAccount);
            Analytics.setUserId(apearAccount);
            setChainIdProp(chainId);
            console.log(library);
        }
    },[library,account,connector,chainId]);
    useAsyncEffect(async ()=>{
        if (provider && chainId){
            
        }
    },[provider,chainId]);
    useEffect(() => {
        if (ethereum) {
            // const onAccountsChanged = async () => {
            //     const accounts = await ethereum.request({ method: "eth_accounts" });
            //     if (accounts?.[0]) {
            //         setAddress(accounts[0]);
            //         Analytics.setUserId(accounts[0]);
            //     } else {
            //         setAddress(null);
            //     }
            // };
            // const onChainChanged = async () => {
            //     let chainId = Number(await ethereum.request({ method: "eth_chainId" }));
            //     console.log("chainId:"+chainId);
            // };
            // const onDisconnect = () => {
            //     setAddress(null);
            //     setEthereum(undefined);
            // };
            // onAccountsChanged();
            // onChainChanged();
            // ethereum.on("accountsChanged", onAccountsChanged);
            // ethereum.on("chainChanged", onChainChanged);
            // ethereum.on("disconnect", onDisconnect);
            // return () => {
            //     ethereum.off("accountsChanged", onAccountsChanged);
            //     ethereum.off("chainChanged", onAccountsChanged);
            //     ethereum.off("disconnect", onDisconnect);
            // };
        }
    }, [ethereum]);

    const approveToken = useCallback(
        async (token: string, spender: string, amount?: ethers.BigNumber) => {
            if (signer && provider) {
                amount = amount || ethers.constants.MaxUint256;
                if (isProviderOnTron(provider)){
                    const ppp = provider.provider as TronWebProvider;
                    let account = ppp.getAccount().hex;
                    let tronAddr = TRON_ADDRESS_MAPPING[spender];
                    let spenderHex = ppp.tronAddressToHex(tronAddr);
                    const erc20 = await getAsyncContract("ERC20", token, provider);
                    console.log("erc20",erc20);
                    
                    const tx = await erc20.approve(spenderHex,amount).send();
                    console.log("tx",tx);
                    return await tronWrapRspToEth(tx);
                }
                const erc20 = getContract("ERC20", token, signer);
                const gasLimit = await erc20.estimateGas.approve(spender, amount);
                const tx = await erc20.approve(spender, amount, {
                    gasLimit
                });
                return await logTransaction(tx, "ERC20.approve()", spender, amount.toString());
            }
        },
    [signer,provider]);

    const getTokenAllowance = useCallback(
        async (token: string, spender: string) => {
            if (provider && address) {
                if (isProviderOnTron(provider)){
                    const erc20 = await getAsyncContract("ERC20", token, provider);
                    console.log("getTokenAllowance",provider);
                    const ppp = provider.provider as TronWebProvider;
                    let account = ppp.getAccount().hex;
                    let spenderHex = ppp.tronAddressToHex(spender);
                    console.log("spenderHex",spenderHex);
                    const allowance = await erc20.allowance(account,spenderHex).call(); 
                    console.log("allowance",allowance);
                    return allowance;
                }
                const erc20 = getContract("ERC20", token, provider);
                return erc20.allowance(address, spender);
            }
        },
    [provider, address]);

    const getTokenBalance = useCallback(
        async (token: string, who: string) => {
            if (provider) {
                if (isProviderOnTron(provider)){
                    const contract = await getAsyncContract("ERC20", token, provider);
                    const bal = await contract.balanceOf(tronAddressToHex(provider,who)).call();
                    return bal;
                }
                const erc20 = getContract("ERC20", token, provider);
                return await erc20.balanceOf(who);
            }
        },
    [provider]);

    const getTotalSupply = useCallback(
        async (token: string) => {
            if (provider) {
                if (isProviderOnTron(provider)){
                    const erc20 = await getAsyncContract("ERC20", token, provider);
                    return await erc20.totalSupply().call();    
                }
                const erc20 = getContract("ERC20", token, provider);
                return await erc20.totalSupply();
            }
        },
    [provider]);

    const addOnBlockListener = useCallback(
        (name, listener) => {
            setOnBlockListeners(old => ({ ...old, [name]: listener }));
        },
    [setOnBlockListeners]);

    const removeOnBlockListener = useCallback(
        name => {
            setOnBlockListeners(old => {
                delete old[name];
                return old;
            });
        },
    [setOnBlockListeners]);

    useEffect(() => {
        if (provider && chainIdProp === 1) {
            const onBlock = async (block: number) => {
                for (const listener of Object.entries(onBlockListeners)) {
                    await listener[1]?.(block);
                }
            };
            provider.on("block", onBlock);
            return () => {
                provider.off("block", onBlock);
            };
        }
    }, [provider, chainIdProp, onBlockListeners]);

    return (
        <EthersContext.Provider
            value={{
                ethereum,
                setEthereum,
                provider,
                signer,
                kovanSigner,
                chainId:chainIdProp,
                address,
                ensName,
                approveToken,
                getTokenAllowance,
                getTokenBalance,
                getTotalSupply,
                addOnBlockListener,
                removeOnBlockListener
            }}>
            {children}
        </EthersContext.Provider>
    );
};

export const EthersContextConsumer = EthersContext.Consumer;
