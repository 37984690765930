import { AbstractConnector } from '@web3-react/abstract-connector'
import { injected, bsc,walletconnect,tron } from '../connectors'
export declare enum ChainId {
    MAINNET = 56,
    BSCTESTNET = 97
}

export interface WalletInfo {
    connector?: AbstractConnector
    name: string
    iconName: string
    description: string
    url: string | null
    href: string | null
    color: string
    primary?: true
    mobile?: true
    mobileOnly?: true
  }

  export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
    BSC: {
      connector: bsc,
      name: 'Binance Smart Chain Wallet',
      iconName: 'binance-wallet.svg',
      description: 'Easy-to-use browser extension.',
      url:"https://chrome.google.com/webstore/detail/binance-chain-wallet/fhbohimaelbohpjbbldcngcnapndodjp",
      href: null,
      color: '#C69915'
    },
    TRON:{
      connector: tron,
      name: 'Tron Link Wallet',
      iconName: 'tronlink.png',
      description: 'Easy-to-use browser extension.',
      url:"https://chrome.google.com/webstore/detail/ibnejdfjmmkpcnlpebklmnkoeoihofec",
      href: null,
      color: '#6266B3'
    },
    INJECTED: {
      connector: injected,
      name: 'Injected',
      iconName: 'arrow-right.svg',
      description: 'Injected web3 provider.',
      url:null,
      href: null,
      color: '#010101',
      primary: true
    },
    METAMASK: {
      connector: injected,
      name: 'MetaMask',
      iconName: 'metamask.png',
      description: 'Easy-to-use browser extension.',
      url:"https://metamask.io/",
      href: null,
      color: '#E8831D'
    },
    // WALLET_CONNECT: {
    //   connector: walletconnect,
    //   name: 'WalletConnect',
    //   iconName: 'walletConnectIcon.svg',
    //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    //   url:null,
    //   href: null,
    //   color: '#4196FC',
    //   mobile: true
    // },
    // WALLET_LINK: {
    //   connector: walletlink,
    //   name: 'Coinbase Wallet',
    //   iconName: 'coinbaseWalletIcon.svg',
    //   description: 'Use Coinbase Wallet app on mobile device',
    //   href: null,
    //   color: '#315CF5'
    // },
    // COINBASE_LINK: {
    //   name: 'Open in Coinbase Wallet',
    //   iconName: 'coinbaseWalletIcon.svg',
    //   description: 'Open in Coinbase Wallet app.',
    //   href: 'https://go.cb-w.com/mtUDhEZPy1',
    //   color: '#315CF5',
    //   mobile: true,
    //   mobileOnly: true
    // },
    // FORTMATIC: {
    //   connector: fortmatic,
    //   name: 'Fortmatic',
    //   iconName: 'fortmaticIcon.png',
    //   description: 'Login using Fortmatic hosted wallet',
    //   href: null,
    //   color: '#6748FF',
    //   mobile: true
    // },
    // Portis: {
    //   connector: portis,
    //   name: 'Portis',
    //   iconName: 'portisIcon.png',
    //   description: 'Login using Portis hosted wallet',
    //   href: null,
    //   color: '#4A6C9B',
    //   mobile: true
    // }
  }

  export const NetworkContextName = 'NETWORK'