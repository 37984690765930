import OrderBook from "@sushiswap/settlement/deployments/kovan/OrderBook.json";
import Settlement from "@sushiswap/settlement/deployments/mainnet/Settlement.json";
import { useLocation } from "react-router";
import getEnvVars from '../../environment';
const {CONTRACT,REACT_APP_CHAIN_ID,NETWORKS} =getEnvVars()

// export const UNISWAP_ROUTER = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
export const SUSHISWAP_ROUTER = "0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f";
export const ROUTER = SUSHISWAP_ROUTER;
export const MASTER_CHEF = "0xc2edad668740f1aa35e4d8f227fb8e17dca888cd";
export const SUSHI_BAR = "0x8798249c2e607446efb7ad49ec89dd1865ff4272";
export const SUSHI_ROLL = "0x16E58463eb9792Bc236d8860F5BC69A81E26E32B";
export const LP_TOKEN_SCANNER = "0xD132Ce8eA8865348Ac25E416d95ab1Ba84D216AF";
export const ZAP_IN = "0xfF350eDc2242Ca4d7252A64746aec4A5487a852B";
export const ZAP_OUT = "0xf12CAB7Acaa14Eed3bEa82199ea2f5c2C2194B81";
export const ORDER_BOOK = OrderBook.address;
export const SETTLEMENT = Settlement.address;

//bsc testnet
export const BTCST = CONTRACT.STOKEN;
export const BTCSTFarm = CONTRACT.FARM;
export const BBTC = CONTRACT.RTOKEN;
export const BRIDGE = CONTRACT.BRIDGE;
export const CHAINID = REACT_APP_CHAIN_ID;

export const TRON = {
    ID:NETWORKS.TRON.ID,
    BTCST:CONTRACT.TRON.BTCST,
    CHAINID:NETWORKS.TRON.CHAINID,
    BRIDGE:CONTRACT.TRON.BRIDGE
}
export const BSC = {
    ID:NETWORKS.BSC.ID,
    BTCST:BTCST,
    CHAINID:NETWORKS.BSC.CHAINID,
    BTCB:CONTRACT.RTOKEN,
    BRIDGE:CONTRACT.BRIDGE
}
const __TRON_ADDRESS_MAPPING = {}
__TRON_ADDRESS_MAPPING[BTCST] = CONTRACT.TRON.BTCST;
__TRON_ADDRESS_MAPPING[BRIDGE] = CONTRACT.TRON.BRIDGE;

export const  TRON_ADDRESS_MAPPING = __TRON_ADDRESS_MAPPING;
// export function TRON_ADDRESS_MAPPING(origin:string)
// {
//     return __TRON_ADDRESS_MAPPING[origin];
// }


export function isChainIdSupport(chainId: number|string){
    let noticeChangeChainId = true;
    const location = useLocation();
    if (location.pathname.startsWith("/ourBridge") && chainId ==1){
        //tron is ok for bridge
        if (window.tronWeb){
            noticeChangeChainId = false;
        }
    }else if (chainId == CHAINID){
        noticeChangeChainId = false;
    }
    return !noticeChangeChainId;
}