import { Web3Provider,ExternalProvider } from '@ethersproject/providers'
import {TronWeb} from "../../types/Ethereum"

class TronWebExternalProvider{
    isMetaMask=false;
    host?: string;
    path?: string;
    public getTron():TronWeb{
        return window.tronWeb!;
    }
    public constructor(){
        
    }
    public sendAsync(request: {
        method: | {method:string};
        params?: Array<any>;
    }, callback: (error: any, response: any) => void){
        console.log("sendAsync",request);
        this.__dealWithRequest(request,callback);
    }
    public send(request: {
        method: | {method:string};
        params?: Array<any>;
    }, callback: (error: any, response: any) => void) {
        console.log("send",request);
        this.__dealWithRequest(request,callback);
    }
    public request(request: {
        method: string | {method:string};
        params?: Array<any>;
    }) : Promise<any>{
        console.log("request",request);
        return this.__dealWithRequest(request);
    }

    private __dealWithRequest(request: {
        method: string | {method:string};
        params?: Array<any>;
    },callback?: (error: any, response: any) => void) : Promise<any>{
        if (request.method=="eth_chainId" || 
            (request.method?.method && request.method?.method=="eth_chainId")
        ){

            return Promise.resolve("1");
        }
        if (request.method=="eth_accounts" ||
            (request.method?.method && request.method?.method=="eth_accounts")
        ){
            let fakeAcc = "0x"+this.getTron().defaultAddress.hex.substr(0,40)
            return Promise.resolve([fakeAcc])
        }
        if (request.method=="eth_call" ||
            (request.method?.method && request.method?.method=="eth_call")
        ){
            console.log("eth_call",request);
        }
        throw new Error("not impl")
    }
}
const TRON_PROVIDER = new TronWebExternalProvider();

export class TronWebProvider extends Web3Provider{
    public isTron = true;

    public getTron():TronWeb{
        return window.tronWeb!;
    }
    public constructor(){
        super(TRON_PROVIDER)
    }
    public getAccount():{hex:string,base58:string}{
        return this.getTron().defaultAddress;
    }
    public tronAddressToHex(address: string):string{
        return this.getTron().address.toHex(address);
    }
}