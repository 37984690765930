import { FACTORY_ADDRESS as SUSHISWAP_FACTORY, Pair } from "@sushiswap/sdk";
import sushiData from "@sushiswap/sushi-data";
import { FACTORY_ADDRESS as UNISWAP_FACTORY } from "@uniswap/sdk";
import { BigNumber, ethers } from "ethers";

import Fraction from "../constants/Fraction";
import { ETH } from "../constants/tokens";
import { Order, OrderStatus } from "../hooks/useSettlement";
import LPToken from "../types/LPToken";
import Token from "../types/Token";
import TokenWithValue from "../types/TokenWithValue";
import { useCallback } from "react";
import { BTCST,BTCSTFarm,BBTC,BRIDGE,TRON, BSC } from "../constants/contracts";
import { logTransaction } from "../utils/analytics-utils";
import {TRON_ADDRESS_MAPPING} from "../constants/contracts"
import {
    convertToken,
    formatBalance,
    getContract,
    isETH,
    isWETH,
    parseBalance,
    parseCurrencyAmount,
    pow10
} from "./index";
import {isProviderOnTron,getAsyncContract,tronWrapRspToEth} from "./getContract"

export const fetchTotalMinedRTokenInpool = async (provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("V2FarmWithApiWithUpgrade",BTCSTFarm,provider);
    const value = await contract.viewAllTimeTotalMined();
    return value;
};

export const fetchCurrentTotalStakedSTokenInpool = async (provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("IBEP20",BTCST,provider);
    const value = await contract.balanceOf(BTCSTFarm);
    return value;
};

export const viewTotalRewardInPoolFrom = async(account: string,provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("V2FarmWithApiWithUpgrade",BTCSTFarm,provider);
    const value = await contract.viewTotalRewardInPoolFrom(account);
    return value;
    
};

export const getTotalRemainingSupplyLocked = async(provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("ISTokenERC20",BTCST,provider);
    const value = await contract.getTotalRemainingSupplyLocked();
    return value;
};

export const viewTotalRewardInPool = async(provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("V2FarmWithApiWithUpgrade",BTCSTFarm,provider);
    const value = await contract.viewTotalRewardInPool();
    return value;
};

export const getFreeToTransferAmount = async(account: string,provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("ISTokenERC20",BTCST,provider);
    const value = await contract.getFreeToTransferAmount(account);
    return value;
};

export const viewUserInfo = async(account: string,provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("V2FarmWithApiWithUpgrade",BTCSTFarm,provider);
    const value = await contract.viewUserInfo(account);
    return value;
};

export const stakeActions = ()=>{
    const enter = useCallback(async (amount: ethers.BigNumber, signer: ethers.Signer) => {
        const farm = getContract("V2FarmWithApiWithUpgrade", BTCSTFarm, signer);
        console.log("before gas limit:"+amount);
        const gasLimit = await farm.estimateGas.apiDepositToMining(amount);
        console.log(await gasLimit.toString()+" gas limit");
        const tx = await farm.apiDepositToMining(amount, {
            gasLimit: gasLimit.mul(105).div(100)
        });
        return logTransaction(tx, "stakeActions.enter()", amount.toString());
    }, []);

    const leave = useCallback(async (amount: ethers.BigNumber, signer: ethers.Signer) => {
        const farm = getContract("V2FarmWithApiWithUpgrade", BTCSTFarm, signer);
        console.log("before gas limit:"+amount);
        const gasLimit = await farm.estimateGas.apiWithdrawLatestSToken(amount);
        console.log(await gasLimit.toString()+" gas limit");
        const tx = await farm.apiWithdrawLatestSToken(amount, {
            gasLimit: gasLimit.mul(105).div(100)
        });
        return logTransaction(tx, "stakeActions.leave()", amount.toString());
    }, []);

    return {
        enter,
        leave
    };
};

export const bridgeActions = ()=>{
    const bridgeOut = useCallback(async (tokenAddr: string,amount: BigNumber,otherSideAddress:string,
            signer: ethers.Signer,provider: ethers.providers.JsonRpcProvider,
            otherNetworkId:number = TRON.ID,
            ) => {
        if (isProviderOnTron(provider)){
            otherNetworkId = BSC.ID;
            const ppp = provider.provider as TronWebProvider;
            const bridge = await getAsyncContract("MayaBridge", BRIDGE, provider);
            let tronAddr = TRON_ADDRESS_MAPPING[tokenAddr];
            let hexAddr = ppp.tronAddressToHex(tronAddr);
            const tx = await bridge.bridgeOut(hexAddr,otherSideAddress,
                BigNumber.from(otherNetworkId),amount).send();
            return await tronWrapRspToEth(tx);                
        }
        const bridge = getContract("MayaBridge", BRIDGE, signer);
        const gasLimit = await bridge.estimateGas.bridgeOut(tokenAddr,otherSideAddress,
            BigNumber.from(otherNetworkId),amount);    
        const tx = await bridge.bridgeOut(tokenAddr,otherSideAddress,
            BigNumber.from(otherNetworkId),amount,{
            gasLimit: gasLimit.mul(100).div(100)
        });
        return logTransaction(tx, "bridgeOut", amount.toString());
    }, []);

    const claimOutBack = useCallback(async (account: string,index: BigNumber,
        signer: ethers.Signer) => {
        const bridge = getContract("MayaBridge", BRIDGE, signer);
        console.log("before gas limit:"+index);
        const gasLimit = await bridge.estimateGas.claimOutBack(account,index);
        console.log(await gasLimit.toString()+" gas limit");
        const tx = await bridge.claimOutBack(account,index,{
            gasLimit: gasLimit.mul(100).div(100)
        });
        return logTransaction(tx, "claimOutBack", account,index.toString());
    }, []);
    const getUserRecordLen = useCallback(async (account: string,
        provider: ethers.providers.JsonRpcProvider,localToken: string =BTCST) => {
        if (isProviderOnTron(provider)){
            localToken = TRON.BTCST;
            const contract = await getAsyncContract("MayaBridge", BRIDGE, provider);
            let localTokenAddr = tronAddressToHex(provider,localToken);
            console.log("tron account,localTokenAddr",account,localTokenAddr);
            const value = await contract.getUserRecordLen(account,localTokenAddr).call();
            console.log("getUserRecordLen value tron",value);
            return value;
        }
        const bridge = getContract("MayaBridge", BRIDGE, provider);
        const value = await bridge.getUserRecordLen(account,localToken);
        return value;
    }, []);
    const getUserRecordIndex = useCallback(async (index:BigNumber,account: string,
        provider: ethers.providers.JsonRpcProvider,localToken: string =BTCST) => {
        if (isProviderOnTron(provider)){
            localToken = TRON.BTCST;
            const contract = await getAsyncContract("MayaBridge", BRIDGE, provider);
            let localTokenAddr = tronAddressToHex(provider,localToken);
            console.log("tron account,localTokenAddr",account,localTokenAddr);
            const value = await contract.userRecord(account,localTokenAddr,index).call();
            return value;
        }
        const bridge = getContract("MayaBridge", BRIDGE, provider);
        const value = await bridge.userRecord(account,localToken,index);
        return value;
    }, []);
    const getRecord = useCallback(async (index: BigNumber,
        provider: ethers.providers.JsonRpcProvider) => {
        if (isProviderOnTron(provider)){
            const contract = await getAsyncContract("MayaBridge", BRIDGE, provider);
            const value = await contract.records(index).call();
            console.log("getRecord value tron",value);
            return value;
        }
        const bridge = getContract("MayaBridge", BRIDGE, provider);
        const value = await bridge.records(index);
        return value;
    }, []);
    const getUserRecord = useCallback(async (index:BigNumber,account: string,
        provider: ethers.providers.JsonRpcProvider,localToken: string =BTCST) => {
        let rindex = await getUserRecordIndex(index,account,provider,localToken);
        if (rindex){
            return getRecord(rindex,provider);
        }
        return undefined;
    }, []);
    return {
        bridgeOut,
        claimOutBack,
        getUserRecordLen,
        getRecord,
        getUserRecord
    }
};
export function tronAddressToHex(provider:any,localToken){
    const hex =window.tronWeb!.address!.toHex(localToken);
    console.log("hex address",hex);
    return hex;
}
export const totalSupplyOfSToken = async(provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("IBEP20",BTCST,provider);
    if (isProviderOnTron(provider)){
        const contract = await getAsyncContract("IBEP20", BTCST, provider);
        const value = await contract.totalSupply().call();
        console.log("totalSupplyOfSToken value tron",value);
        return value;
    }
    const value = await contract.totalSupply();
    return value;
};
export const getCirculatingBTCSTSupply = async(provider: ethers.providers.JsonRpcProvider)=>{
    if (isProviderOnTron(provider)){
        const contract = await getAsyncContract("IBEP20", BTCST, provider);
        const value = await contract.totalSupply().call();
        const bal = await contract.balanceOf(tronAddressToHex(provider,TRON.BRIDGE)).call();
        console.log("totalSupplyOfSToken value tron",value,bal);
        return value.sub(bal);
    }
    const contract = getContract("IBEP20",BTCST,provider);
    const value = await contract.totalSupply();
    const bal = await contract.balanceOf(BRIDGE);
    return value.sub(bal);
}
export const getBridgeTokenPair = async(provider: ethers.providers.JsonRpcProvider,
        localToken: string = BTCST,
        networkId:number=TRON.ID)=>{
        if (isProviderOnTron(provider)){
            const bridge = await getAsyncContract("MayaBridge", BRIDGE, provider);
            console.log("tron isProviderOnTron",bridge);
            
            networkId = BSC.ID;
            localToken = TRON.BTCST;
            let localTokenAddr = tronAddressToHex(provider,localToken);
            const value = await bridge.bridgeTokenPair(localTokenAddr,networkId).call();
            console.log("getBridgeTokenPair value tron",value);
            return value;
        }
        const bridge = getContract("MayaBridge", BRIDGE, provider);
        const value = await bridge.bridgeTokenPair(localToken,networkId);
        return value;
};

export const viewTotalMinedRewardFrom = async(account: string,provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("V2FarmWithApiWithUpgrade",BTCSTFarm,provider);
    const value = await contract.viewTotalMinedRewardFrom(account);
    return value;
};
export const viewTotalClaimedRewardFrom = async(account: string,provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("V2FarmWithApiWithUpgrade",BTCSTFarm,provider);
    const value = await contract.viewTotalClaimedRewardFrom(account);
    return value;
};
export const viewGetTotalRewardBalanceInPool = async(account: string,provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("V2FarmWithApiWithUpgrade",BTCSTFarm,provider);
    const value = await contract.viewGetTotalRewardBalanceInPool(account);
    return value;
};

export const apiClaimAmountOfReward = async(account: string,amount: ethers.BigNumber,reCalculate :boolean ,signer: ethers.Signer)=>{
    const contract = getContract("V2FarmWithApiWithUpgrade",BTCSTFarm,signer);
    const gasLimit = await contract.estimateGas.apiClaimAmountOfReward(account,amount,reCalculate);
    const tx = await contract.apiClaimAmountOfReward(account,amount,reCalculate, {
        gasLimit: gasLimit.mul(120).div(100)
    });
    return logTransaction(tx, "farmActions.apiClaimAmountOfReward()", amount.toString());
};


export const fetchBtcMiningStat = async ()=>{
    // const response = await fetch("https://pool.binance.cc/mining-api/v1/public/pool/price/priceKline?algoId=1");
    const response = await fetch("https://584xqc7ik2.execute-api.us-east-2.amazonaws.com/beta/bp-relay");
    
    const json = await response.json();
    if (json.code != 0){
        return {code:json.code,msg:json.msg};
    }
    const dayList = json.data.dayList;
    const hourList = json.data.hourList;
    return {code:0,dayList:dayList,hourList:hourList};
}

export async function getBTCSTPrice() {
    const response = await fetch("https://584xqc7ik2.execute-api.us-east-2.amazonaws.com/beta/gp-replay");

    const data = await response.json();

    const source = (data?.data?.market_pairs || []).find(pair => {
        return pair && pair.market_pair === "BTCST/USDT" && pair.exchange?.name === "Binance";
    });

    return source?.quote?.USD?.price || 0;
}

export const viewRoundSlot = async(timeKey: number,provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("V2FarmWithApiWithUpgrade",BTCSTFarm,provider);
    const value = await contract.viewRoundSlot(timeKey);
    return value;
};

export const _roundSlotsReward = async(timeKey: number,token: string,provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("V2FarmWithApiWithUpgrade",BTCSTFarm,provider);
    const value = await contract._roundSlotsReward(timeKey,token);
    return value;
};

export const viewFarmBasicInfo = async(provider: ethers.providers.JsonRpcProvider)=>{
    const contract = getContract("V2FarmWithApiWithUpgrade",BTCSTFarm,provider);
    console.log(contract);
    const data = await Promise.all(
        ["_farmStartedTime", "_miniStakePeriodInSeconds", "_farmDescription"].map(field => {
            try {
                return contract.callStatic[field]();
            } catch (e) {
                console.log(e);
                return "";
            }
        })
    );
    return {
        started: data[0],
        stakePeriod: data[1],
        desc: data[2]
    };
};
